import React from 'react';

import { errorBoundaryPropTypes } from './proptypes';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo,
        });
    }

    render() {
        const { main } = this.props;
        const { error, errorInfo } = this.state;
        const env = process.env.CONFIG_SOURCE ||
        process.env.REACT_APP_CONFIG_SOURCE ||
        process.env.NODE_ENV ||
        'development';

        if (errorInfo) {
            return (
                <div className={`${main ? 'p24' : ''}`}>
                    {main
                        ? <h2>Something went wrong.</h2>
                        : <b className="title-font">Something went wrong.</b>}
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {error?.toString() && env !== 'production'}
                        <br />
                        {errorInfo.componentStack}
                    </details>
                </div>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    ...errorBoundaryPropTypes
};

export default ErrorBoundary;
