/* eslint max-len: ["off"] */
import urljoin from 'url-join';
import PackageJson from '../package.json';
import localConf from './config-local';
import configConstants from './config-constants';

if (localConf) {
    console.log('LocalConf', localConf);
} else {
    console.log('No local config overrides');
}

const buildId = process.env.BUILD_ID || 'default';
const appCodePath = `${PackageJson.version}-${buildId}`;
const outputPath = 'leagues-client';
const applicationVersion = PackageJson.version;
const staticContentPath = 'public';

const rootDomain = 'utrsports.net';
const brandedDomain = 'utrsports.net';
const twitterHandle = 'UTR_Sports_';

const devFontUrl = 'https://cloud.typography.com/6323816/6761592/css/fonts.css'; // doesn't count against production page views
const localFontUrl = '/fonts/utr-fonts.css'; // for internal/offline use
const prodFontUrl = 'https://cloud.typography.com/6323816/6355392/css/fonts.css';

const webServerPublicPath = '/';

const links = {
    support: `https://support.${rootDomain}/support/tickets/new`,
    supportHome: `https://support.${rootDomain}/en/support/home`,
    appStoreUrl: 'https://apps.apple.com/us/app/universal-tennis/id1519232627',
    googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.ut.utr&utm_source=google-play&utm_campaign=web-footer-google-playstore-badge&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
    sessionStandingsRule: `https://support.${rootDomain}/support/solutions/articles/9000235929-set-session-standings-rule`

};

const devRoxKey = '5b4f55a7a43ccb0e97402f6f';
const devStripeKey = 'pk_test_fJzi8lcnQbbKhlMJp9YVZ2tA00Wx6NIMm2';
const utrStripeAccountId = process.env.UTR_STRIPE_ACCOUNT_ID || 'acct_1AV2NnBJJJ7ji1oI';
const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

const prodSegmentKey = 'XJQ5EvgdaXEKA7B8XgaiPgOPWjO0GB2Q';
const nonProdSegmentKey = 'kGabTpvRe2bSYtu4QmKKmoPf5Q6kY2Xc';

const prodFacebookPixelId = '934768840401987';
const nonProdFacebookPixelId = '123456789123456';

const devApiAuthToken = '2303cc78-0e7e-4b86-8ae6-74d05cd10cdf';

const prodStatsigKey = 'client-M26U2xrmdwgAiTbeNrUpxyGIk4i2guVzMiPPm9m00b';
const devStatsigKey = 'client-BS9ru6yhmKmFidDYBKkoSTiTwuV1p1nEDXl7IXtANJD';

const localDevSettings = {
    appHost: 'https://localhost:3443',
    marketingAppHost: 'http://localhost:3001',
    leaguesAppHost: 'http://localhost:3002',
    apiAuthToken: devApiAuthToken,
    apiHost: `https://test1-api.${rootDomain}`,
    leaguesApiHost: `https://test1-leagues-api.${rootDomain}`,
    searchApiHost: `https://test1-search-api.${rootDomain}`,
    siteBaseUrl: rootDomain,
    brandedUrl: brandedDomain,
    twitterAccount: twitterHandle,
    fontUrl: localFontUrl,
    roxKey: devRoxKey,
    links,
    stripeHost: 'https://connect.stripe.com',
    stripeClientId: 'ca_G7kABhwUKLT9L05CMvE33btClNTT5xvn',
    stripeKey: devStripeKey,
    utrStripeAccountId,
    useProxy: true,
    googleApiKey,
    publicPath: webServerPublicPath,
    pixelId: nonProdFacebookPixelId,
    cdnUrl: `https://ci-cdn.${rootDomain}`,
    isLocalhost: true,
    statsigKey: devStatsigKey,
    ...(localConf || {}),
};

const createTestSettings = (environmentName) => {
    const cdnUrl = `https://${environmentName}-cdn.${rootDomain}`;
    const cdnUrlStatic = `https://${environmentName}-cdn-static.${rootDomain}`;
    const apiUri = process.env.APIHOST || `https://${environmentName}-api.${rootDomain}`;
    const leaguesApiUri = process.env.LEAGUESAPIHOST || `https://${environmentName}-leagues-api.${rootDomain}`;
    const searchApiUri = `https://${environmentName}-search-api.${rootDomain}`;

    return {
        siteBaseUrl: process.env.siteBaseUrl || rootDomain,
        brandedUrl: process.env.brandedUrl || brandedDomain,
        twitterAccount: process.env.twitterAccount || twitterHandle,
        apiAuthToken: process.env.REACT_APP_API_AUTH_TOKEN,
        appHost: process.env.APPHOST || `https://${environmentName}-app.${rootDomain}`,
        appHostName: process.env.APPHOSTNAME || `${environmentName}-app.${rootDomain}`,
        marketingAppHost: process.env.MARKETINGAPPHOST || `https://${environmentName}.${rootDomain}`,
        apiHost: apiUri,
        googleApiKey,
        leaguesApiHost: leaguesApiUri,
        searchApiHost: searchApiUri,
        userSiteHost: process.env.USERSITEHOST || `https://${environmentName}-app.${rootDomain}`,
        userSiteHostName: process.env.USERSITEHOST || `${environmentName}-app.${rootDomain}`,
        fontUrl: devFontUrl,
        roxKey: devRoxKey,
        links,
        stripeHost: 'https://connect.stripe.com',
        stripeClientId: 'ca_G7kABhwUKLT9L05CMvE33btClNTT5xvn',
        stripeKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
        utrStripeAccountId,
        cdnUrl,
        publicPath: urljoin(cdnUrlStatic, outputPath, appCodePath),
        segmentKey: nonProdSegmentKey,
        devtoolConfig: 'eval-source-maps',
        pixelId: nonProdFacebookPixelId,
        statsigKey: devStatsigKey,
    };
};

const prodCdnUrl = `https://prod-cdn.${rootDomain}`;
const prodCdnUrlStatic = `https://prod-cdn-static.${rootDomain}`;
const prodApiUri = process.env.APIHOST || `https://api.${rootDomain}`;
const prodLeaguesApiUri = process.env.LEAGUESAPIHOST || `https://leagues-api.${rootDomain}`;
const searchApiUrl = `https://search-api.${rootDomain}`;
const prodServerSettings = {
    siteBaseUrl: process.env.siteBaseUrl || rootDomain,
    brandedUrl: process.env.brandedUrl || brandedDomain,
    apiAuthToken: process.env.REACT_APP_API_AUTH_TOKEN,
    twitterAccount: process.env.twitterAccount || twitterHandle,
    appHost: process.env.APPHOST || `https://app.${rootDomain}`,
    marketingAppHost: process.env.MARKETINGAPPHOST || `https://${rootDomain}`,
    leaguesApiHost: prodLeaguesApiUri,
    apiHost: prodApiUri,
    searchApiHost: searchApiUrl,
    fontUrl: prodFontUrl,
    googleApiKey,
    links,
    stripeHost: 'https://connect.stripe.com',
    stripeClientId: process.env.STRIPE_CLIENT_ID || 'ca_G7kArGiIoboxUDj62elK8kxXinfQe0QI',
    stripeKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    utrStripeAccountId,
    roxKey: process.env.REACT_APP_ROX_KEY,
    cdnUrl: prodCdnUrl,
    publicPath: urljoin(prodCdnUrlStatic, outputPath, appCodePath),
    segmentKey: prodSegmentKey,
    devtoolConfig: false,
    pixelId: prodFacebookPixelId,
    statsigKey: prodStatsigKey,
};

const qaServerSettings = createTestSettings('qa');
const ciServerSettings = createTestSettings('ci');
const test1ServerSettings = createTestSettings('test1');
const test2ServerSettings = createTestSettings('test2');
const test3ServerSettings = createTestSettings('test3');
const test4ServerSettings = createTestSettings('test4');
const test5ServerSettings = createTestSettings('test5');
const test6ServerSettings = createTestSettings('test6');
const test7ServerSettings = createTestSettings('test7');
const test8ServerSettings = createTestSettings('test8');
const test9ServerSettings = createTestSettings('test9');
const test10ServerSettings = createTestSettings('test10');
const test11ServerSettings = createTestSettings('test11');
const rcSettings = createTestSettings('rc');

let config = localDevSettings; // Default to local dev settings

const configSource = (
    process.env.CONFIG_SOURCE ||
    process.env.REACT_APP_CONFIG_SOURCE ||
    process.env.NODE_ENV ||
    'development'
).toLowerCase();

switch (configSource) {
    case 'prod':
        config = prodServerSettings;
        break;
    case 'qa':
        config = qaServerSettings;
        break;
    case 'ci':
        config = ciServerSettings;
        break;
    case 'test1':
        config = test1ServerSettings;
        break;
    case 'test2':
        config = test2ServerSettings;
        break;
    case 'test3':
        config = test3ServerSettings;
        break;
    case 'test4':
        config = test4ServerSettings;
        break;
    case 'test5':
        config = test5ServerSettings;
        break;
    case 'test6':
        config = test6ServerSettings;
        break;
    case 'test7':
        config = test7ServerSettings;
        break;
    case 'test8':
        config = test8ServerSettings;
        break;
    case 'test9':
        config = test9ServerSettings;
        break;
    case 'test10':
        config = test10ServerSettings;
        break;
    case 'test11':
        config = test11ServerSettings;
        break;
    case 'rc':
        config = rcSettings;
        break;
    default:
        config = localDevSettings;
}

// Common settings
config.outputPath = configConstants.outputPath;
config.appCodePath = appCodePath;
config.applicationVersion = applicationVersion;
config.staticContentPath = staticContentPath;

export default config;
